<template>
  <div class="SalarieProfileEdition" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Editer votre profil</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- Déjà connecté -->

            <div v-if="!isAuthenticated">
              <h2 class="text-center">
                Vous n'êtes pas connecté.
              </h2>
              <div class="row text-center">
                <div class="col-12">
                  <contactComponent />
                </div>
              </div>

              <b-button
                block variant="outline-primary" class="mt-4"
                @click='goToLogin'>
                  Connectez-vous
              </b-button>
            </div>

            <div v-else>

                <!-- ERROR MODAL -->

                <b-modal ref="salarieUpdateAPIErrorModal" hide-footer title="Erreur lors la mise à jour de votre profil">
                  <div class="text-center">
                    <p>Une erreur s'est produite lors de la mise à jour de votre profil, veuillez vérifier les données renseignées ou nous
                    contacter via notre <router-link to="/contact"> formulaire de contact</router-link>
                    si cette erreur persiste </p>
                    <b-button class="mt-3" variant="outline-success" @click="hideSalarieUpdateAPIErrorModal">Fermer</b-button>
                  </div>
                </b-modal>

                <b-modal ref="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
                <div class="text-center">
                  <p>Une erreur s'est produite lors du chargement des informations de votre profil,
                  veuillez vérifier les données renseignées ou nous contacter à l'adresse <router-link to="/contact"> formulaire de contact</router-link>
                  si cette erreur persiste </p>
                  <b-button class="mt-3" variant="outline-success" @click="hideGlobalAPILoadingErrorModal">Fermer</b-button>
                </div>
              </b-modal>

              <b-modal ref="salarieUpdatedModal" hide-footer title="Votre profil a bien été mis à jour">
                <div class="text-center">
                  <p><strong>Votre profil a bien été mis à jour</strong></p>
                  <b-button class="mt-3" variant="outline-success" @click="hidesalarieUpdatedModalModal">Fermer</b-button>
                </div>
              </b-modal>

              <loading
                :active.sync="salarieLoading"
                :can-cancel="false"
                :is-full-page="true"
                color="#BB3634">
              </loading>

              <div>
                <b-form>
                  <div class="row">
                    <div class="col-sm-6">
                      <b-form-group id="update-salarie-first_name-group" label="Prénom" label-for="update-salarie-first_name-input">
                        <b-form-input
                          v-model="firstName" type="text"
                          maxlength="30"
                          placeholder="Prénom"
                          id="update-salarie-first_name-input"
                          @input="$v.firstName.$touch()"
                          :state="$v.firstName.$dirty ? !$v.firstName.$error : null">
                        </b-form-input>
                        <div class="form-error" v-if="$v.firstName.$error">Votre prénom doit comporter entre 2 et 30 caractères</div>
                      </b-form-group>
                    </div>
                    <div class="col-sm-6">
                      <b-form-group id="update-salarie-last_name-group" label="Nom" label-for="update-salarie-last_name-input">
                        <b-form-input
                          v-model="lastName" type="text"
                          maxlength="150"
                          placeholder="Nom"
                          id="update-salarie-last_name-input"
                          @input="$v.lastName.$touch()"
                          :state="$v.lastName.$dirty ? !$v.lastName.$error : null">
                        </b-form-input>
                        <div class="form-error" v-if="$v.lastName.$error">Votre nom doit comporter entre 2 et 150 caractères</div>
                      </b-form-group>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-sm-6">
                      <b-form-group id="update-salarie-birthdate-group" label="Date de naissance" label-for="update-salarie-birthdate-input">
                        <b-form-input
                          v-model="birthdate" type="date"
                          @input="$v.birthdate.$touch()"
                          :state="$v.birthdate.$dirty ? !$v.birthdate.$error : null">
                        </b-form-input>
                        <div v-if="$browserDetect.isIE || $browserDetect.isSafari" class="text-info">
                          <small>Vous utilisez Internet Explorer ou Safari, veuillez entrer votre
                          date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                        </div>
                        <div class="form-error" v-if="!$v.birthdate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</div>
                      </b-form-group>
                    </div>
                    <div class="col-sm-6">
                      <b-form-group id="update-salarie-phone-number-group" label="Votre numéro de téléphone" label-for="update-salarie-phone-number-input">
                        <b-form-input
                          id="update-salarie-phone-number-input"
                          type="tel" maxlength="10"
                          v-model.trim.lazy="phoneNumber"
                          @input="$v.phoneNumber.$touch()"
                          :state="$v.phoneNumber.$dirty ? !$v.phoneNumber.$error : null"
                          placeholder="Entrez votre numéro de téléphone">
                        </b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <b-form-group id="update-salarie-professional_category-group" label="Catégorie professionnelle" label-for="update-salarie-professional_category-input">
                        <b-form-select
                          v-model="professionalCategory"
                          id="update-salarie-professional_category-input"
                          @input="$v.professionalCategory.$touch()"
                          :state="$v.professionalCategory.$dirty ? !$v.professionalCategory.$error : null"
                          :options='professionalCategoryArrayForm'>
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="col-sm-6">
                      <b-form-group id="update-salarie-employeur-group" label="Votre employeur actuel" label-for="update-salarie-employeur-input">
                        <b-form-input
                          id="update-salarie-employeur-input"
                          type="text" maxlength="50"
                          v-model="employeur"
                          @input="$v.employeur.$touch()"
                          :state="$v.employeur.$dirty ? !$v.employeur.$error : null"
                          placeholder="Nom de votre employeur">
                        </b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group id="update-salarie-muna-group" label="Votre numéro de Muna, si connu (facultatif)" label-for="update-salarie-muna-input">
                    <b-form-input
                      id="update-salarie-muna-input"
                      type="text" maxlength="11"
                      v-model.trim.lazy="muna"
                      @input="$v.muna.$touch()"
                      :state="$v.muna.$dirty ? !$v.muna.$error : null"
                      placeholder="XXXXXXXX.XX">
                    </b-form-input>
                    <small class="text-info">Votre Muna comporte 8 chiffres, 1 point et 2 chiffres (XXXXXXXX.XX)</small>
                  </b-form-group>

                  <b-button
                    block :variant="updateSalarieButtonStyle" class="mt-2"
                    @click='updateSalarie'
                    :disabled="updateSalarieInProcess || $v.firstName.$invalid || $v.lastName.$invalid || $v.birthdate.$invalid || $v.phoneNumber.$invalid || $v.professionalCategory.$invalid || $v.employeur.$invalid || $v.muna.$invalid">
                      <b-spinner small label="Create Salarie Spinner" v-if="updateSalarieInProcess"></b-spinner> {{ updateSalarieButtonText }}
                  </b-button>
                </b-form>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, integer} from 'vuelidate/lib/validators'
import { APISalarieConnected } from '@/api/APISalarieConnected'
import { isDate, isMuna } from '@/validators/validators'
import contactComponent from '@/components/contactComponent'
import formTitleComponent from '@/components/formTitleComponent'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


const apiSalarieConnectedService = new APISalarieConnected()

export default {
  name: 'SalarieProfileEdition',
  components: {
    contactComponent,
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      salarieLoading: false,

      firstName: '',
      lastName: '',
      birthdate: '',
      phoneNumber: '',
      professionalCategory: 'SAL',
      employeur: '',
      muna: '',

      professionalCategoryArrayForm: [
        { text: 'Salarié permanent', value: 'SAL' },
        { text: 'Artiste et technicien du spectacle', value: 'ART' },
        { text: 'Indemnisé Pôle Emploi', value: 'POL' },
        { text: 'Autres', value: 'AUT' },
      ],

      updateSalarieButtonText: 'Mettre à jour votre profil',
      updateSalarieButtonStyle: 'primary',
      updateSalarieInProcess: false,
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.state.auth.token
    },
  },
  validations: {
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(30),
      required
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(150),
      required
    },
    birthdate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    phoneNumber: {
      required,
      integer,
      minLength: minLength(10),
      maxLength: maxLength(16)
    },
    professionalCategory: {
      required,
    },
    employeur: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(50)
    },
    muna: {
      isMuna,
    },
  },

  created: function() {
    this.getConnectedSalarie()
  },

  methods: {
    getConnectedSalarie () {
      this.salarieLoading = true
      apiSalarieConnectedService.getConnectedSalarie(this.token)
        .then((result) => {
          this.firstName = result.data.user.first_name
          this.lastName = result.data.user.last_name
          this.birthdate = result.data.birthdate
          this.phoneNumber = result.data.phone_number
          this.professionalCategory = result.data.professional_category
          this.employeur = result.data.employeur
          this.muna = result.data.muna

        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.salarieLoading = false
        })
    },

    updateSalarie () {
      this.updateSalarieInProcess = true
      this.updateSalarieButtonText = "Mise à jour en cours"
      this.updateSalarieButtonStyle = 'secondary'
      apiSalarieConnectedService.updateSalarieProfil(
        this.token, this.firstName, this.lastName,
        this.birthdate, this.phoneNumber, this.professionalCategory,
        this.employeur, this.muna)
      .then(() => {
        this.$refs.salarieUpdatedModal.show()
      })
      .catch(() => {
        this.$refs.salarieUpdateAPIErrorModal.show()
      })
      .finally(() => {
        this.updateSalarieInProcess = false
        this.updateSalarieButtonText = "Mettre à jour votre profil"
        this.updateSalarieButtonStyle = 'primary'
      })

    },
    hideSalarieUpdateAPIErrorModal () {
      this.$refs.salarieUpdateAPIErrorModal.hide()
    },
    hideGlobalAPILoadingErrorModal () {
      this.$refs.globalAPILoadingErrorModal.hide()
    },
    hidesalarieUpdatedModalModal () {
      this.$refs.salarieUpdatedModal.hide()
    },
    goToLogin () {
      this.$router.push('/login')
    },
  }
}
</script>
